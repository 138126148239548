import { useEffect } from "react";
import { setInterval, clearInterval } from "@/helpers/workerTimers";

const UPDATE_PERIOD_MS = 20;

export default function useStatusWithProgress(state, updateState, paused) {
	useEffect(() => {
		if (!paused) {
			let previousTimeMs = Date.now();
			const interval = setInterval(() => {
				const timeMs = Date.now();
				const dtime = 0.001 * (timeMs - previousTimeMs);
				previousTimeMs = timeMs;

				updateState((draft) => {
					if (state.name !== draft.name) {
						return;
					}

					// if (state.duration <= 0) {
					// 	draft.progress = 1;
					// 	draft.elapsedTime = draft.duration;
					// } else {
					const dprogress = (dtime * (1 - draft.progress)) / Math.max(state.duration - draft.elapsedTime, 0);
					if (draft.progress + dprogress < 1) {
						draft.progress = draft.progress + dprogress;
						draft.elapsedTime = draft.elapsedTime + dtime;
					} else {
						draft.progress = 1;
						draft.elapsedTime = draft.duration;
					}
					// }
				});
			}, UPDATE_PERIOD_MS);

			return () => void clearInterval(interval);
		}
	}, [paused, state.name, state.duration, updateState]);
}
