import React, { memo, useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";

import gsap from "@/helpers/gsap";

import trans from "@/helpers/trans";
import onWindowResize from "@/helpers/onWindowResize";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import max from "lodash/max";
import sum from "lodash/sum";
import isArray from "lodash/isArray";
import isString from "lodash/isString";

import useConfetti from "@/hooks/useConfetti";
import { createAvatarImageJSON } from "@/helpers/avatar";

import Header from "@/components/Header";
import CoverImage from "@/components/CoverImage";
import ProgressIndicator from "@/components/ProgressIndicator";

import IconStar from "@/images/icons/star-border.svg";
import IconCorrect from "@/images/icons/icon-correct-multicolor.svg";
import IconProfile from "@/images/icons/icon-profile-border.svg";
import IconMouse from "@/images/icons/icon-mouse-arrow-border.svg";
import IconResetTimer from "@/images/icons/icon-vote-reset-timer.svg";
import ReloadIcon from "@/images/icons/icon-reload-outline.svg";

import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, ANSWER5, ANSWER6, WHITE } from "@/colors";

import {
	PLAY_STATUS_VOTE_PRE_COMING_UP,
	PLAY_STATUS_VOTE_COMING_UP,
	PLAY_STATUS_VOTE_PREP,
	PLAY_STATUS_VOTE_SHOW,
	PLAY_STATUS_VOTE_SUSPENSE,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_VOTE_END,
	QUIZ_DEAL_ID,
	VOTE_MODE_NORMAL,
	VOTE_MODE_HOST_DECIDES,
	VOTE_MODE_CRAZY_CLICK_MODE,
	CDN_BASE_URL,
} from "@/constants";

import { useMemo } from "react";
import { ANSWER_RECEIVED_SOUNDS, sfx, WRONG_ANSWER_SOUNDS } from "@/helpers/audio";
import TextToSpeech from "./pages/TextToSpeech";
import Billboard from "./pages/Billboard";
import isEqual from "lodash/isEqual";
import update from "lodash/update";
import { getDifficulty } from "@/helpers/difficulty";
import { apiUpdateSession } from "@/api/quiz";
import isUUID from "@/helpers/isUUID";
import getQuizName from "@/helpers/getQuizName";

const LOAD_TIMEOUT = 5000;

const QUIZ_NAME_BASE_FONT_SIZE = 20 / 16;
const QUIZ_NAME_STYLE = { fontSize: `${QUIZ_NAME_BASE_FONT_SIZE}rem` };

const STATUS_UNSELECTED = -1;
const STATUS_NONE = 0;
const STATUS_SELECTED = 1;

export const PLAY_STATUSES_VOTE = [
	PLAY_STATUS_VOTE_PRE_COMING_UP,
	PLAY_STATUS_VOTE_COMING_UP,
	PLAY_STATUS_VOTE_PREP,
	PLAY_STATUS_VOTE_SHOW,
	PLAY_STATUS_VOTE_SUSPENSE,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_VOTE_END,
];

function VoteOptionsButton({ className, active = false, disabled = false, isHost, ...props }) {
	return (
		<button
			disabled={disabled}
			className={tailwindCascade(
				"h-12",
				"p-2",
				"rounded-lg",
				"relative",
				"overflow-hidden",
				"flex",
				"flex-row",
				"items-center",
				"justify-start",
				"font-bold",
				"text-lg",
				"bg-black",
				"bg-opacity-30",
				"text-white",
				{
					"opacity-50": !isHost,
				},
				className
			)}
			{...props}
		>
			<div className="flex flex-row items-center px-2 space-x-6">
				<div className={`w-6 h-6 rounded-full ${active ? "bg-green-light" : "bg-white-50"} relative`}>
					{active && (
						<div className="top-1/2 left-1/2 absolute w-2 h-2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full"></div>
					)}
				</div>
				<div>{props.children}</div>
			</div>
		</button>
	);
}

function QuizVote({
	isHost = false,
	isPaused = false,
	numQuestionsPerRound,
	nextQuizId = null,
	numRoundsPerGame,
	onVote = (quizId) => {},
	onComplete = null,
	quizzes = null,
	setNextQuizId,
	sessionId,
	slideIndex,
	statusWithProgress = null,
	voteQuizId = null,
	numberOfVotes = {},
	avatars = {},
	numConnectedPlayers = 0,
	onResetTimer,
	resetTimer = false,
	voteMode = VOTE_MODE_NORMAL,
	onNewVoteMode = null,
	reducedMotion = false,
}) {
	const ref = useRef(null);

	const onCompleteRef = useRef(onComplete);
	useEffect(() => void (onCompleteRef.current = onComplete), [onComplete]);

	const [showNextQuiz, setShowNextQuiz] = useState(false);
	const [voteModeAnimationActive, setVoteModeAnimationActive] = useState(false);

	const prevVoteMode = useRef(VOTE_MODE_NORMAL);

	useEffect(() => {
		let timeout = null;

		if (voteMode && voteMode !== prevVoteMode.current) {
			prevVoteMode.current = voteMode;
			setVoteModeAnimationActive(true);
			sfx.play("lbPointRecieved");

			timeout = setTimeout(() => {
				setVoteModeAnimationActive(false);
			}, 1000);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [voteMode]);

	const progress = useMemo(() => {
		switch (statusWithProgress.name) {
			case PLAY_STATUS_VOTE_PRE_COMING_UP:
				return 0;
			case PLAY_STATUS_VOTE_COMING_UP:
				return 0;
			case PLAY_STATUS_VOTE_PREP:
				return 0;
			case PLAY_STATUS_VOTE_SHOW:
				return statusWithProgress.progress;
			case PLAY_STATUS_VOTE_SUSPENSE:
				return 1;
			case PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ:
				return 1;
			case PLAY_STATUS_VOTE_END:
				return 1;
		}
	}, [statusWithProgress.name, statusWithProgress.progress]);

	const previousStatusWithProgressNameRef = useRef(statusWithProgress.name);
	const [isVoteDone, setIsVoteDone] = useState(false);
	useEffect(() => {
		const previousStatusWithProgressName = previousStatusWithProgressNameRef.current;
		previousStatusWithProgressNameRef.current = statusWithProgress.name;

		if (statusWithProgress.name === PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ) {
			setIsVoteDone(true);
		} else if (
			previousStatusWithProgressName === PLAY_STATUS_VOTE_SHOW &&
			statusWithProgress.name === PLAY_STATUS_VOTE_SUSPENSE
		) {
			setIsVoteDone(true);
		} else {
			setIsVoteDone(false);
		}
	}, [statusWithProgress.name]);

	/*
	const isVoteDone = useMemo(
	  () =>
		(statusWithProgress.name === PLAY_STATUS_VOTE_SHOW && progress === 1) ||
		statusWithProgress.name === PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	  [statusWithProgress.name, progress]
	);
	*/

	const reducedMotionRef = useRef(reducedMotion);
	useEffect(() => void (reducedMotionRef.current = reducedMotion), [reducedMotion]);

	useEffect(() => {
		if (setNextQuizId && isVoteDone && nextQuizId === null) {
			const result = quizzes
				.map((quiz) => ({
					quizId: quiz.id,
					score: (numberOfVotes[quiz.id] || 0) + Math.random(),
				}))
				.sort((a, b) => b.score - a.score);

			const quizId = result?.[0]?.quizId;
			if (sessionId && quizId && isUUID(quizId)) {
				apiUpdateSession({ sessionId, quizId }).catch((err) => void console.error(err));
			}

			setNextQuizId(quizId ?? null);
		}
	}, [isVoteDone, nextQuizId, quizzes, setNextQuizId, numberOfVotes, sessionId]);

	const showConfetti = useConfetti({
		particleCount: 200,
		angle: 90,
		spread: 360,
		startVelocity: 89,
		decay: 0.6,
		gravity: 0.6,
		drift: 0,
		ticks: 200,
		colors: [ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE],
		shapes: ["square", "square", "square", "circle"],
		scalar: 1.9,
	});
	const showConfettiRef = useRef(showConfetti);
	useEffect(() => void (showConfettiRef.current = showConfetti), [showConfetti]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ && nextQuizId) {
			const item = ref.current.querySelector(`[data-quiz="${nextQuizId}"]`);
			if (item) {
				setShowNextQuiz(true);
				if (!reducedMotionRef.current && showConfettiRef.current) {
					showConfettiRef.current(ref.current, item);
				}
			}
		}
	}, [nextQuizId, statusWithProgress.name]);

	const numVotesPerQuiz = useMemo(
		() => (quizzes || []).map((quiz) => numberOfVotes[quiz.id] || 0),
		[quizzes, numberOfVotes]
	);

	// Use a string to detect changing/new votes because it compares by value
	const numVotesPerQuizStr = numVotesPerQuiz.join(",");
	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_VOTE_SHOW) {
			const numVotesPerQuiz = numVotesPerQuizStr.split(",").map((n) => parseInt(n));
			if (sum(numVotesPerQuiz) > 0) {
				sfx.play(ANSWER_RECEIVED_SOUNDS, false, 0.4);
			}
		}
	}, [numVotesPerQuizStr, statusWithProgress.name]);

	const temperature = useMemo(() => {
		const temperature = [];
		if (quizzes) {
			const maxNumVotesForAnyQuiz = max(numVotesPerQuiz);
			const allPlayersHaveVoted = sum(numVotesPerQuiz) >= numConnectedPlayers;
			const shakeMore = allPlayersHaveVoted && numConnectedPlayers > 1;

			for (let i = 0; i < quizzes.length; i++) {
				if (maxNumVotesForAnyQuiz > 0 && numVotesPerQuiz[i] === maxNumVotesForAnyQuiz) {
					if (
						numConnectedPlayers > 1 &&
						numVotesPerQuiz.filter((n) => n === maxNumVotesForAnyQuiz).length === 1
					) {
						// Single leader
						temperature.push(shakeMore ? 3 : 2);
					} else {
						// One of multiple leaders (or alone player)
						temperature.push(shakeMore ? 2 : 1);
					}
				} else {
					// Not a leader
					temperature.push(0);
				}
			}
		}
		return temperature;
	}, [quizzes, numVotesPerQuiz, numConnectedPlayers]);

	const roundText =
		slideIndex / numQuestionsPerRound + 1 === numRoundsPerGame
			? trans("Final round")
			: trans("Round %d of %d", slideIndex / numQuestionsPerRound + 1, numRoundsPerGame || 0);
	const chooseText = trans("Choose a quiz for the next %d questions", numQuestionsPerRound || 0);

	const dimQuizzes = voteMode === VOTE_MODE_HOST_DECIDES && !isHost;

	return (
		<div className="container relative min-h-full mx-auto">
			<Billboard
				text1={roundText}
				text2={chooseText}
				visible={statusWithProgress.name === PLAY_STATUS_VOTE_COMING_UP}
			/>

			<div
				ref={ref}
				className={tailwindCascade(
					"absolute flex flex-col items-center justify-start",
					"w-full h-full pt-6 max-h-[1000px]",
					"-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2",
					{
						"pointer-events-none": statusWithProgress.name !== PLAY_STATUS_VOTE_SHOW,
						"sm:pt-16": isHost,
					}
				)}
			>
				<div className="relative flex flex-col items-center w-full max-w-lg">
					<div
						className={tailwindCascade(
							"flex flex-col items-center w-full max-w-lg px-2",
							"transition-opacity duration-500 delay-500 opacity-0",
							{
								"opacity-100": [
									PLAY_STATUS_VOTE_SHOW,
									PLAY_STATUS_VOTE_SUSPENSE,
									PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
								].includes(statusWithProgress.name),
							}
						)}
					>
						<div
							className={tailwindCascade(
								"flex flex-col w-full gap-2 transition-opacity duration-300 relative",
								{
									"opacity-0": ![PLAY_STATUS_VOTE_SHOW].includes(statusWithProgress.name),
								}
							)}
						>
							<button
								className={`${voteModeAnimationActive || !isHost ? "opacity-0" : "opacity-100"}`}
								disabled={statusWithProgress.name !== PLAY_STATUS_VOTE_SHOW}
								onClick={() => {
									if (onResetTimer) {
										sfx.play("lbPlayerAppears");
										onResetTimer();
									}
								}}
							>
								<IconResetTimer
									className={`md:w-9 md:h-9 h-8 w-8 absolute top-1 right-0 px-2 py-1 text-white bg-black bg-opacity-25 rounded-lg ${
										isHost ? "hover:bg-opacity-50" : "opacity-50"
									}`}
								/>
							</button>
							<Header
								className={tailwindCascade(
									"md:text-xl pb-2 text-lg text-center transition duration-500 translate sm:m-0 mr-4",
									{ "opacity-0": voteModeAnimationActive }
								)}
							>
								{voteMode === VOTE_MODE_HOST_DECIDES
									? trans("Host decides!")
									: trans("All players, vote for the next quiz:")}
							</Header>
							<ProgressIndicator
								progress={progress}
								className={tailwindCascade(
									"w-full h-8 transition-transform scale-150 duration-300 ease-in-out",
									{ "opacity-0": voteModeAnimationActive },
									{ "scale-100": !resetTimer }
								)}
								paused={isPaused}
								visible={true}
								isQuestion={false}
							/>
							<div
								className={tailwindCascade(
									"absolute left-1/2 top-1/2 w-full transition-all transform -translate-x-1/2 -translate-y-1/2 scale-100 duration-500 ease-[cubic-bezier(0,2.5,0.8,1)]",
									{
										"opacity-0": !voteModeAnimationActive,
										"scale-0": !voteModeAnimationActive,
									}
								)}
							>
								<Header className="md:text-4xl flex justify-center w-full text-3xl font-black text-white">
									{voteMode === VOTE_MODE_NORMAL
										? trans("One vote per player")
										: voteMode === VOTE_MODE_CRAZY_CLICK_MODE
										? trans("Unlimited votes")
										: trans("Host decides")}
								</Header>
							</div>
						</div>
					</div>
					<div
						className={tailwindCascade(
							"flex flex-row flex-wrap w-full max-w-lg transition-opacity duration-500 delay-500",
							{
								"opacity-0": [
									PLAY_STATUS_VOTE_PRE_COMING_UP,
									PLAY_STATUS_VOTE_COMING_UP,
									PLAY_STATUS_VOTE_PREP,
									PLAY_STATUS_VOTE_END,
								].includes(statusWithProgress.name),
							}
						)}
					>
						{quizzes &&
							quizzes.map((quiz, index) => (
								<Quiz
									key={index}
									className={tailwindCascade("opacity-25", {
										"opacity-100":
											(statusWithProgress.name === PLAY_STATUS_VOTE_SHOW && !dimQuizzes) ||
											(statusWithProgress.name === PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ &&
												nextQuizId === quiz.id),
										"opacity-50": dimQuizzes && statusWithProgress.name === PLAY_STATUS_VOTE_SHOW,
									})}
									isHost={isHost}
									quiz={quiz}
									disabled={
										(!voteMode === VOTE_MODE_CRAZY_CLICK_MODE && !!nextQuizId) ||
										(voteMode === VOTE_MODE_HOST_DECIDES && !isHost)
									}
									active={
										voteMode === VOTE_MODE_CRAZY_CLICK_MODE ? undefined : quiz.id === voteQuizId
									}
									status={
										nextQuizId === null
											? STATUS_NONE
											: nextQuizId === quiz.id
											? STATUS_SELECTED
											: STATUS_UNSELECTED
									}
									onClick={(ev) => {
										onVote(quiz.id);
									}}
									playVoice={showNextQuiz && quiz.id === nextQuizId}
									color={[ANSWER1, ANSWER2, ANSWER3, ANSWER4, ANSWER5, ANSWER6][index]}
									visible={[
										PLAY_STATUS_VOTE_SHOW,
										PLAY_STATUS_VOTE_SUSPENSE,
										PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
										PLAY_STATUS_VOTE_END,
									].includes(statusWithProgress.name)}
									index={index}
									temperature={temperature[index]}
									isNextQuiz={
										statusWithProgress.name === PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ &&
										quiz.id === nextQuizId
									}
									numPlayers={numberOfVotes[quiz.id] || 0}
									voteMode={voteMode}
									avatars={avatars[quiz.id]}
									isPaused={isPaused}
								/>
							))}
					</div>
				</div>
				<div
					className={tailwindCascade(
						"flex flex-col items-center w-full space-y-2 font-bold text-white mt-4 max-w-lg px-4",
						"transition-opacity duration-500 delay-1000",
						{
							"opacity-0": [
								PLAY_STATUS_VOTE_PRE_COMING_UP,
								PLAY_STATUS_VOTE_COMING_UP,
								PLAY_STATUS_VOTE_PREP,
								PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
								PLAY_STATUS_VOTE_END,
							].includes(statusWithProgress.name),
						}
					)}
				>
					<VoteOptionsButton
						disabled={statusWithProgress.name !== PLAY_STATUS_VOTE_SHOW || !isHost}
						onClick={() => {
							if (onNewVoteMode) {
								onNewVoteMode(VOTE_MODE_NORMAL);
							}
						}}
						active={voteMode === VOTE_MODE_NORMAL}
						className="w-full"
						isHost={isHost}
					>
						{trans("One vote per player")}
					</VoteOptionsButton>
					<VoteOptionsButton
						disabled={statusWithProgress.name !== PLAY_STATUS_VOTE_SHOW || !isHost}
						onClick={() => {
							if (onNewVoteMode) {
								onNewVoteMode(VOTE_MODE_CRAZY_CLICK_MODE);
							}
						}}
						active={voteMode === VOTE_MODE_CRAZY_CLICK_MODE}
						className="w-full"
						isHost={isHost}
					>
						{trans("Unlimited votes")}
					</VoteOptionsButton>
					<VoteOptionsButton
						disabled={statusWithProgress.name !== PLAY_STATUS_VOTE_SHOW || !isHost}
						onClick={() => {
							if (onNewVoteMode) {
								onNewVoteMode(VOTE_MODE_HOST_DECIDES);
							}
						}}
						active={voteMode === VOTE_MODE_HOST_DECIDES}
						className="w-full"
						isHost={isHost}
					>
						{trans("Host decides")}
					</VoteOptionsButton>
				</div>
			</div>
		</div>
	);
}

function AvatarImage({ avatar = null }) {
	const [url, setUrl] = useState(null);

	useEffect(() => {
		if (isArray(avatar)) {
			const size = 40;
			let mounted = true;
			(async () => {
				try {
					const border = Math.round(size / 10);
					const image = await createAvatarImageJSON({
						avatar: avatar,
						width: size * 2,
						height: size * 2,
						border,
					});
					if (mounted) {
						setUrl(image);
					}
				} catch (error) {
					console.error(error);
				}
			})();

			return () => void (mounted = false);
		} else if (isString(avatar)) {
			setUrl(`${CDN_BASE_URL}/${avatar}`);
		}
	}, [avatar]);

	if (url) {
		if (isArray(avatar)) {
			return <img src={url} alt="" className="md:w-10 md:h-10 w-7 h-7 max-w-none" />;
		} else if (isString(avatar)) {
			return (
				<img
					className="md:w-10 md:h-10 w-7 h-7 max-w-none bg-petrol-light overflow-hidden border-2 border-black rounded-full"
					src={url}
					alt=""
				/>
			);
		}
	}

	return null;
}

function Quiz({
	className,
	index,
	quiz,
	isHost,
	onClick = (ev) => {},
	onLoad = null,
	disabled = false, // true means you can't interact with the butto
	active, // true means the button is "lit
	status = STATUS_NONE,
	avatars,
	color,
	temperature,
	visible, // true means the button is show
	isNextQuiz,
	numPlayers,
	voteMode,
	isPaused,
}) {
	const quizName = getQuizName(quiz);
	const quizMedia = quiz?.media;

	const ref = useRef(null);
	const quizNameWrapperRef = useRef(null);
	const quizNameRef = useRef(null);

	const [isLoaded, setIsLoaded] = useState(false);

	const onLoadCoverImage = useCallback(() => {
		if (!isLoaded) {
			setIsLoaded(true);
			if (onLoad) {
				onLoad();
			}
		}
		if (ref.current) {
			ref.current.dataset.loaded = true;
		}
	}, [isLoaded, onLoad]);

	const onLoadCoverImageRef = useRef(onLoadCoverImage);
	useEffect(() => void (onLoadCoverImageRef.current = onLoadCoverImage), [onLoadCoverImage]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (onLoadCoverImageRef.current) {
				onLoadCoverImageRef.current();
			}
		}, LOAD_TIMEOUT);
		return () => void clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (quizNameWrapperRef.current && quizNameRef.current) {
			const resize = () => {
				if (quizNameRef.current && quizNameWrapperRef.current) {
					const { style } = quizNameRef.current;
					style.fontSize = `${QUIZ_NAME_BASE_FONT_SIZE}rem`;
					const scale =
						1 /
						Math.max(
							quizNameRef.current.offsetWidth / quizNameWrapperRef.current.offsetWidth,
							quizNameRef.current.offsetHeight / quizNameWrapperRef.current.offsetHeight,
							1
						);
					style.fontSize = `${QUIZ_NAME_BASE_FONT_SIZE * scale}rem`;
				}
			};

			resize(); // Initial resiz

			return onWindowResize(resize);
		}
	}, [quizName]);

	const numPlayersElementRef = useRef(null);

	const { isEasy, isHard } = getDifficulty(quiz, quiz.slideCount);

	useEffect(() => {
		// Animate number of players votin
		if (numPlayersElementRef.current) {
			if (numPlayers > 0) {
				const timeline = gsap.timeline();

				timeline.to(numPlayersElementRef.current, {
					duration: 0.25,
					scale: 2.0,
				});

				timeline.to(numPlayersElementRef.current, {
					duration: 0.25,
					scale: 1.0,
				});

				timeline.play();
				return () => timeline.kill();
			} else {
				gsap.set(numPlayersElementRef.current, {
					scale: 1.0,
				});
			}
		}
	}, [numPlayers]);

	return (
		<div
			ref={ref}
			className={tailwindCascade(
				"w-1/2 p-2 opacity-0 relative",
				{ "opacity-100 animate-media": visible },
				className
			)}
			data-quiz={quiz.id}
			style={{
				animationDelay: `${500 + 100 * index}ms`,
				animationFillMode: "backwards",
			}}
		>
			<TextToSpeech isHost={isHost} play={isNextQuiz} voice="Samantha" text={quiz.name} />
			<div
				className={tailwindCascade("flex flex-col flex-shrink-0 w-full", {
					// "opacity-50": active === false
					"opacity-25": disabled && status === STATUS_NONE && voteMode !== VOTE_MODE_HOST_DECIDES,
					"animate-shakeminor": status === STATUS_NONE && temperature === 1 && !isPaused,
					"animate-shake": status === STATUS_NONE && temperature === 2 && !isPaused,
					"animate-shakemajor": status === STATUS_NONE && temperature === 3 && !isPaused,
				})}
				style={{ animationDelay: `${(-500 * index) / 6}ms` }}
			>
				<button
					className={tailwindCascade("pb-4/3", "group", "relative", "w-full", "transform", "z-0", {
						"active:top-1": !(disabled || active),
					})}
					disabled={disabled || active}
					onClick={onClick}
					onMouseEnter={() => {
						if (!active && !disabled) {
							sfx.play("hoverFeedback", false, 0.75);
						}
					}}
				>
					<div className="top-2 left-2 z-3 absolute flex flex-row-reverse items-center gap-1">
						<div className="flex flex-row-reverse items-center h-0">
							{avatars &&
								avatars.map((avatar, index) => (
									<div key={index} className="md:h-10 md:w-[22px] w-3.5 h-7 overflow-visible">
										<AvatarImage avatar={avatar} />
									</div>
								))}
						</div>

						<div ref={numPlayersElementRef} className="flex flex-row gap-0.5 overflow-visible">
							{voteMode === VOTE_MODE_CRAZY_CLICK_MODE && <IconMouse className="w-4" />}
							{voteMode !== VOTE_MODE_CRAZY_CLICK_MODE && <IconProfile className="w-4" />}
							<Header className="text-base">{numPlayers || "0"}</Header>
						</div>
					</div>

					{active && status === STATUS_NONE && (
						<div className="-right-1 -bottom-1 z-5 absolute">
							<IconCorrect
								className="border-3 w-10 h-10 text-black border-black rounded-full"
								style={{ fill: color }}
							/>
						</div>
					)}
					<div className="rounded-xl z-1 absolute top-0 left-0 block w-full h-full overflow-hidden border-4 border-black border-solid">
						{quiz.id === QUIZ_DEAL_ID ? (
							<div className="bg-opacity-20 w-full h-full bg-white" />
						) : (
							<>
								<div className="absolute top-0 left-0 w-full h-full">
									<CoverImage media={quizMedia} width={320} height={240} onLoad={onLoadCoverImage} />
								</div>
								<img
									src="/images/gradients/quiz-vignette-invert.png"
									alt=""
									className={tailwindCascade(
										"absolute",
										"top-0",
										"left-0",
										"block",
										"w-full",
										"h-full",
										"opacity-50",
										"z-2",
										{
											"group-hover:opacity-25": !disabled && !active && status === STATUS_NONE,
											invisible: !isLoaded,
										}
									)}
								/>
							</>
						)}
						<div className="absolute top-0 left-0 w-full h-full p-4 overflow-hidden">
							<div
								ref={quizNameWrapperRef}
								className="relative flex flex-col items-center justify-center w-full h-full gap-1"
							>
								{quiz.id === QUIZ_DEAL_ID ? (
									<div className="flex flex-col gap-2">
										<ReloadIcon className="md:h-18 h-14" />
										<Header
											className="md:text-base text-sm text-center origin-center"
											textClassName="leading-none"
											wrapper="h3"
											textStrokeWidth={(1 / 4) * 4}
											textShadow={false}
										>
											{quizName}
										</Header>
									</div>
								) : (
									<>
										<Header
											ref={quizNameRef}
											className="md:text-xl text-lg text-center origin-center"
											textClassName="leading-none"
											style={QUIZ_NAME_STYLE}
											wrapper="h3"
											textStrokeWidth={(1 / 4) * 4}
											textShadow={false}
										>
											{quizName}
										</Header>
									</>
								)}

								{(quiz.ratingAvg || isEasy || isHard) && (
									<div className="z-5 flex flex-row items-center gap-1">
										{quiz.ratingAvg ? (
											<>
												<IconStar className="h-4 text-white" />
												<Header className="text-base leading-4">
													{quiz.ratingAvg.toFixed(1)}
												</Header>
											</>
										) : (
											<></>
										)}
										{isEasy && (
											<img
												src="/images/icons/icon-label-easy.svg"
												alt=""
												className="block w-auto h-6"
											/>
										)}
										{isHard && (
											<img
												src="/images/icons/icon-label-hard.svg"
												alt=""
												className="block w-auto h-6"
											/>
										)}
									</div>
								)}

								{quiz.generated && (
									<img
										src="/images/icons/icon-label-ai-generated.svg"
										alt=""
										className="z-5 block w-auto h-6 mb-1"
									/>
								)}
							</div>
						</div>
						<div
							className={tailwindCascade(
								"rounded-lg",
								"duration-250",
								"z-4",
								"absolute",
								"top-0",
								"w-full",
								"h-full",
								"transition-opacity",
								"ease-in-out",
								"border-4",
								{
									"border-[12px]": active && status === STATUS_NONE,
								}
							)}
							style={{ borderColor: color }}
						/>
					</div>
				</button>
			</div>
		</div>
	);
}
export default memo(QuizVote, isEqual);
