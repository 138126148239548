import { tailwindCascade } from "@/helpers/tailwindCascade";
import useRefMounted from "@/hooks/useRefMounted";
import Header from "../Header";
import TextToSpeech from "./TextToSpeech";

export default function Billboard({ text1, text2 = null, visible, ...props }) {
	const [mounted] = useRefMounted();
	return (
		<div
			className={tailwindCascade(
				"absolute flex flex-col items-center justify-center w-full h-full gap-4 p-2 text-center"
			)}
		>
			<Header
				className={tailwindCascade("text-3xl md:text-5xl", "opacity-0 transition-opacity duration-300", {
					"opacity-100": visible && mounted,
				})}
			>
				{text1}
			</Header>
			{text2 && (
				<Header
					className={tailwindCascade("text-lg md:text-3xl", "opacity-0 transition-opacity duration-300", {
						"opacity-100 delay-[1500ms]": visible && mounted,
					})}
				>
					{text2}
				</Header>
			)}
			<TextToSpeech
				text={text2 ? [text1, text2] : text1}
				voice="Samantha"
				isHost={true}
				play={visible && mounted}
			/>
		</div>
	);
}
